export class RegexPattern {
    public static readonly Email = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/;
    public static readonly UserName = /^[\w\W\sㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9ぁ-んァ-ヴ]{2,20}$/;
    public static readonly Password =
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~ ])([a-zA-Z]|\d|[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~ ])+$/;
    public static readonly CompanyName = /^[\w\W\sㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9ぁ-んァ-ヴ]{0,30}$/;
    public static readonly WorkspaceName = /^[\w\W\sㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9ぁ-んァ-ヴ]{2,50}$/;
    public static readonly WorkspaceSlug = /^[a-z|0-9|]{2,20}$/;
    public static readonly PhoneNumber = /^[0-9]{9,12}$/;
    public static readonly GroupName = /^[\w\W\sㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9ぁ-んァ-ヴ]{1,15}$/;
    public static readonly KeywordBoosting = /^[가-힣|a-z|A-Z|0-9|\s]+$/;
    public static readonly MeetingURL =
        /^(https:\/\/)?((teams\.microsoft\.com\/l\/meetup-join\/[A-Za-z0-9-._/\\?=&#%+]+)|([-\w]{4,}\.)?zoom.us\/[A-Za-z0-9-._/\\?=\\&\\#%\\+]+|(meet\.google\.com\/[A-Za-z0-9-._]+))/;
    public static readonly MeetingURLWithoutProtocol =
        /^(teams\.microsoft\.com\/l\/meetup-join\/[A-Za-z0-9-._/\\?=&#%+]+)|^([-\w]{4,}\.)?zoom.us\/[A-Za-z0-9-._/\\?=\\&\\#%\\+]+|^(meet\.google\.com\/[A-Za-z0-9-._]+)/;
    public static readonly HexColor = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
    public static readonly HexColorText = /^[0-9a-fA-F]*$/;
}
